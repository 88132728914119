import axiosInstance from '@utils/plugins/API';

const NotificationService = {
  all: (params) => {
    return axiosInstance.get('/notifications', { params });
  },
  create: (title, body) => {
    return axiosInstance.post('/notifications', {
      title,
      body
    });
  },
  update: (id, title, body) => {
    return axiosInstance.post(`/notifications/${id}/update`, {
      title,
      body
    });
  },
  find: (id) => {
    return axiosInstance.get(`/notifications/${id}/show`);
  },
  destroy: (id) => {
    return axiosInstance.post(`notifications/${id}/delete`);
  },
  notify: (id) => {
    return axiosInstance.get(`notifications/${id}/notify`);
  }
};

export default NotificationService;
