import React, { useEffect, useState } from 'react';
import ViewModal from '@core/modals/ViewModal';
import ViewButton from '@core/buttons/atoms/ViewButton';
import RowDetails from '@hoc/cruds/RowDetails';
import NotificationService from '@services/NotificationService';

const ShowNotification = ({ notification }) => {
  const [showModal, setShowModal] = useState(false);
  const [notificationData, setNotificationData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };
  useEffect(() => {
    if (showModal)
      NotificationService.find(notification.id).then((response) => {
        setNotificationData(response.data.data);
      });
  }, [notification.id, showModal]);

  return (
    <>
      <ViewButton onClick={() => setShowModal(true)} />
      <ViewModal width="sm:w-1/3" show={showModal} title="Overview" onClose={closeModal}>
        <div className="flex flex-col mt-5">
          <RowDetails label="Title" value={notificationData.title} />
          <RowDetails label="Body" value={notificationData.body} />
        </div>
      </ViewModal>
    </>
  );
};

export default ShowNotification;
