import React from 'react';

const TermsAndConditions = () => {
  return (
    <div className="font-sans text-base leading-relaxed p-5">
      <h1 className="text-3xl text-center font-bold mb-8">CEA Terms and Conditions</h1>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">1. Registration</h2>
        <p className="mb-2">
          1.1. To access certain features of CEA, such as viewing nearby attractions you must create
          an account. You agree to provide accurate, current, and complete information during the
          the registration process.
        </p>
        <p>
          1.2. You are responsible for maintaining the confidentiality of your account credentials
          and for all activities that occur under your account. You agree to notify us immediately
          of any unauthorized use of your account.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">2. Attractions</h2>
        <p className="mb-2">
          2.1. CEA allows users to browse and explore various attractions categorized by type and
          tags for easy searching.
        </p>
        <p className="mb-2">
          2.2. The information provided about attractions is based on the latest available data, but
          CEA does not guarantee the accuracy or completeness of this information.
        </p>
        <p>
          2.3. Users are responsible for verifying the details of any attractions they wish to
          attend, including location, time, and any associated costs.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">3. User Conduct</h2>
        <p className="mb-2">
          3.1. You agree to use CEA only for lawful purposes and in accordance with these Terms and
          Conditions.
        </p>
        <p>
          3.2. You may not use CEA in any manner that could disable, overburden, damage, or impair
          the Service or interfere with any other party's use of the Service.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">4. Intellectual Property</h2>
        <p>
          4.1. CEA and its original content, features, and functionality are owned by CEA and are
          protected by international copyright, trademark, patent, trade secret, and other
          intellectual property or proprietary rights laws.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">5. Limitation of Liability</h2>
        <p>
          5.1. CEA shall not be liable for any indirect, incidental, special, consequential, or
          punitive damages, including without limitation, loss of profits, data, use, goodwill, or
          other intangible losses, resulting from your access to or use of the Service.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">6. Data Retention/Deletion Policies</h2>
        <p>
          6.1. CEA retains user data for as long as necessary to provide the services and fulfill
          the purposes outlined in the Privacy Policy. Users have the right to request deletion of
          their data.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">7. Delete Account</h2>
        <p>
          7.1. To delete your account and associated data, please{' '}
          <a
            href="/delete-account"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-500 underline">
            click here
          </a>
          .
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">8. Changes to Terms and Conditions</h2>
        <p>
          8.1. CEA reserves the right to update or modify these Terms and Conditions at any time
          without prior notice. Your continued use of the Service after any such changes constitutes
          your acceptance of the new Terms and Conditions.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">9. Governing Law</h2>
        <p>
          9.1. These Terms and Conditions shall be governed by and construed in accordance with the
          laws of Albania, without regard to its conflict of law provisions.
        </p>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">10. Contact Us</h2>
        <p>
          10.1. If you have any questions about these Terms and Conditions, please contact us at{' '}
          <a href="mailto:info@organizatakreo.org" className="text-blue-500 underline">
            info@organizatakreo.org
          </a>
          .
        </p>
      </section>

      <footer className="text-center mt-12 text-sm">
        <p>
          By using the CEA app, you acknowledge that you have read, understood, and agree to be be
          bound by these Terms and Conditions. If you do not agree to these Terms and Conditions,
          you may not access or use the Service.
        </p>
      </footer>
    </div>
  );
};

export default TermsAndConditions;
