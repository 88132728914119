import React from 'react';
import BlankModal from '@core/modals/BlankModal';
import { FaLink } from 'react-icons/fa';

const LinksModal = ({ show, title, children, onClose, loading, label, ...props }) => {
  return (
    <BlankModal
      show={show}
      title={title}
      onClose={onClose}
      width="sm:w-3/5"
      icon={<FaLink className="text-blue-700" />}
      iconBg="bg-blue-100"
      {...props}>
      {children}
    </BlankModal>
  );
};
export default LinksModal;
