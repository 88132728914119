import React, { useEffect, useState } from 'react';
import EditModal from '@core/modals/EditModal';
import EditButton from '@core/buttons/atoms/EditButton';
import Input from '@core/inputs/Input';
import { showError, showSuccess } from '@utils/helpers';
import NotificationService from '@services/NotificationService';
import TextArea from '@core/textareas/TextArea';

const EditNotification = ({ notification, onEdited }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');

  const closeModal = () => {
    setShowModal(false);
    setTitle('');
    setBody('');
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    NotificationService.update(notification.id, title, body)
      .then(() => {
        showSuccess('Notification edited successfully');
        onEdited();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      NotificationService.find(notification.id).then((response) => {
        setTitle(response.data.data.title);
        setBody(response.data.data.body);
      });
  }, [notification.id, showModal]);

  return (
    <>
      <EditButton onClick={() => setShowModal(true)} />
      <EditModal
        width="sm:w-1/3"
        show={showModal}
        title="Edit notification"
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}
        label="Edit">
        <div className="flex flex-col mt-4 sm:mt-8 mb-2 gap-y-5">
          <Input
            height="h-10"
            label="Title"
            placeholder="Title"
            value={title}
            onChange={setTitle}
          />
          <TextArea label="Body" placeholder="Body" value={body} onChange={setBody} />
        </div>
      </EditModal>
    </>
  );
};

export default EditNotification;
