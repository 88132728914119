import React from 'react';
import DeleteEntity from '@hoc/cruds/DeleteEntity';
import AttractionService from '@services/AttractionService';
import ShowAttraction from '@components/Attraction/ShowAttraction';
import EditAttraction from '@components/Attraction/EditAttraction';
import ManageImages from '@components/Attraction/partials/ManageImages';
import ManageLinks from '@components/Attraction/partials/ManageLinks';

const AttractionActions = ({ attraction, onEdited, onDeleted, onManaged }) => {
  return (
    <div className="grid grid-cols-5 gap-2">
      <ManageLinks onManaged={onManaged} attractionId={attraction?.id} />
      <ManageImages onManaged={onManaged} attraction={attraction} />
      <ShowAttraction attraction={attraction} />
      <EditAttraction attraction={attraction} onEdited={onEdited} />
      <DeleteEntity service={AttractionService} id={attraction.id} onDeleted={onDeleted} />
    </div>
  );
};

export default AttractionActions;
