import axiosInstance from '@utils/plugins/API';

const AttractionService = {
  all: (params) => {
    return axiosInstance.get('/attractions', { params });
  },
  create: (payload) => {
    return axiosInstance.post('/attractions', payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  update: (id, payload) => {
    return axiosInstance.post(`/attractions/${id}/update`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  find: (id) => {
    return axiosInstance.get(`/attractions/${id}/show`);
  },
  destroy: (id) => {
    return axiosInstance.post(`attractions/${id}/delete`);
  },
  deleteImage: (id) => {
    return axiosInstance.post(`attractions/${id}/delete-image`);
  },
  setPrimaryImage: (attractionImageId, attractionId) => {
    return axiosInstance.post(`/attractions/${attractionImageId}/${attractionId}/set-primary`);
  },
  uploadImages: (attractionId, payload) => {
    return axiosInstance.post(`/attractions/${attractionId}/upload-images`, payload, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  addLink: (attractionId, payload) => {
    return axiosInstance.post(`/attractions/${attractionId}/create-link`, payload);
  },
  deleteLink: (linkId) => {
    return axiosInstance.delete(`attraction-link/${linkId}/delete-link`);
  }
};

export default AttractionService;
