import React, { useEffect, useState } from 'react';
import RowDetails from '@hoc/cruds/RowDetails';
import NotificationService from '@services/NotificationService';
import { FaBell, FaSpinner } from 'react-icons/fa';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import BlankModal from '@core/modals/BlankModal';
import { showError, showSuccess } from '@utils/helpers';

const NotifyUsers = ({ notification }) => {
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationData, setNotificationData] = useState([]);

  const closeModal = () => {
    setShowModal(false);
  };

  const notify = () => {
    setLoading(true);
    NotificationService.notify(notification.id)
      .then(() => {
        showSuccess('Users were notified');
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal)
      NotificationService.find(notification.id).then((response) => {
        setNotificationData(response.data.data);
      });
  }, [notification.id, showModal]);

  return (
    <>
      <DefaultButton
        sm
        label={
          <div className="flex items-center">
            <FaBell />
          </div>
        }
        bgColor="bg-yellow-100"
        bgColorHover="hover:bg-yellow-200"
        textColor="text-yellow-700"
        onClick={() => setShowModal(true)}
      />
      <BlankModal
        width="sm:w-1/3"
        show={showModal}
        title="Notify Users"
        onClose={closeModal}
        maxWidth="3xl"
        icon={<FaBell className="text-yellow-700" />}
        iconBg="bg-yellow-100"
        otherButtons={[
          <DefaultButton
            bgColor="bg-yellow-500"
            bgColorHover="hover:bg-yellow-600"
            disabled={loading}
            sm
            onClick={notify}
            label={
              <div className="flex items-center">
                {loading ? (
                  <FaSpinner className="animate-spin mr-1" />
                ) : (
                  <FaBell className="mr-1" />
                )}
                Notify
              </div>
            }
          />
        ]}>
        <div className="flex flex-col mt-5">
          <RowDetails label="Title" value={notificationData.title} />
          <RowDetails label="Body" value={notificationData.body} />
        </div>
      </BlankModal>
    </>
  );
};

export default NotifyUsers;
