import React, { useState } from 'react';
import AddButton from '@core/buttons/atoms/AddButton';
import AddModal from '@core/modals/AddModal';
import Input from '@core/inputs/Input';
import { showError, showSuccess } from '@utils/helpers';
import NotificationService from '@services/NotificationService';
import TextArea from '@core/textareas/TextArea';

const CreateNotification = ({ onAdded }) => {
  const [showModal, setShowModal] = useState(false);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [loading, setLoading] = useState(false);

  const closeModal = () => {
    setShowModal(false);
    setTitle('');
    setBody('');
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoading(true);
    NotificationService.create(title, body)
      .then(() => {
        showSuccess('Notification created successfully');
        onAdded();
        closeModal();
      })
      .catch((err) => {
        showError(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <AddButton label="Add Notification" onClick={() => setShowModal(true)} />
      <AddModal
        show={showModal}
        width="sm:w-1/3"
        title="Add Notification"
        onClose={closeModal}
        onSubmit={onSubmit}
        loading={loading}>
        <div className="flex flex-col mt-4 sm:mt-8 mb-2 gap-y-5">
          <Input
            height="h-10"
            label="Title"
            placeholder="Title"
            value={title}
            onChange={setTitle}
          />
          <TextArea label="Body" placeholder="Body" value={body} onChange={setBody} />
        </div>
      </AddModal>
    </>
  );
};

export default CreateNotification;
