import React from 'react';

const Input = (props) => {
  const { id, label, placeholder, onChange, error, onFocus, value } = props;
  return (
    <div>
      <label className="w-full text-sm font-semibold" htmlFor={id}>
        {label}
      </label>
      <textarea
        id={id}
        autoComplete="off"
        className={`caret-brand-light-blue placeholder-light-gray
        rounded px-3 py-2 text-xs sm:text-sm mt-2
                    appearance-none bg-transparent border
                    w-full text-gray-700 mr-3 
                    leading-tight focus:outline-none
                    resize-none focus:border-brand-light-blue
                    ${error && 'border-red-400'}
                    `}
        rows="4"
        onChange={(e) => onChange(e.target.value)}
        onClick={onFocus}
        value={value}
        placeholder={placeholder}
        aria-label={label}
      />
      <div className={`text-xs text-red-400 pt-2 ${error ? '' : 'pb-4'}`}>
        {error ? error.message : ''}
      </div>
    </div>
  );
};

export default Input;
