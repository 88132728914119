import React from 'react';
import DeleteEntity from '@hoc/cruds/DeleteEntity';
import ShowNotification from '@components/Notification/ShowNotification';
import NotifyUsers from '@components/Notification/NotifyUsers';
import NotificationService from '@services/NotificationService';
import EditNotification from '@components/Notification/EditNotification';

const NotificationActions = ({ notification, onEdited, onDeleted }) => {
  return (
    <div className="flex gap-x-2 justify-between items-center">
      <NotifyUsers notification={notification} />
      <ShowNotification notification={notification} />
      <EditNotification notification={notification} onEdited={onEdited} />
      <DeleteEntity service={NotificationService} id={notification.id} onDeleted={onDeleted} />
    </div>
  );
};

export default NotificationActions;
