import React, { useState, useEffect } from 'react';
import { FaLink, FaTrashAlt } from 'react-icons/fa';
import DefaultButton from '@core/buttons/electrons/DefaultButton';
import LinksModal from '@core/modals/LinksModal';
import AttractionService from '@services/AttractionService';
import { showError, showSuccess } from '@utils/helpers';

const LINK_TYPES = {
  'logo-instagram': 'Instagram',
  'logo-facebook': 'Facebook',
  'logo-tiktok': 'TikTok',
  'link-outline': 'General Link'
};

const ManageLinks = ({ attractionId }) => {
  const [links, setLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [newLink, setNewLink] = useState({ type: '', url: '' });

  const closeModal = () => setShowModal(false);

  const fetchLinks = () => {
    setLoading(true);
    AttractionService.find(attractionId)
      .then((response) => {
        setLinks(response?.data?.data?.links || []);
        setLoading(false);
      })
      .catch(() => {
        showError('Failed to load links');
        setLoading(false);
      });
  };

  useEffect(() => {
    if (showModal) {
      fetchLinks();
    }
    // eslint-disable-next-line
  }, [showModal, attractionId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewLink({ ...newLink, [name]: value });
  };

  const handleSubmit = () => {
    if (!newLink.type || !newLink.url) {
      showError('Please fill all fields');
      return;
    }

    setLoading(true);
    AttractionService.addLink(attractionId, newLink)
      .then(() => {
        showSuccess('Link added successfully');
        setNewLink({ type: '', url: '' });
        fetchLinks();
      })
      .catch(() => {
        showError('Failed to add link');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDelete = (linkId) => {
    setLoading(true);
    AttractionService.deleteLink(linkId)
      .then(() => {
        showSuccess('Link deleted successfully');
        fetchLinks();
      })
      .catch(() => {
        showError('Failed to delete link');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <DefaultButton
        sm
        label={
          <div className="flex items-center">
            <FaLink />
          </div>
        }
        bgColor="bg-blue-100"
        bgColorHover="hover:bg-blue-200"
        textColor="text-blue-700"
        onClick={() => setShowModal(true)}
      />
      <LinksModal show={showModal} title="Manage Links" onClose={closeModal} loading={loading}>
        <div className="py-5">
          <div className="flex items-center space-x-4 mb-6">
            <select
              name="type"
              className="flex-1 form-select border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500 transition ease-in-out duration-150"
              value={newLink.type}
              onChange={handleInputChange}>
              <option value="">Select Link Type</option>
              {Object.keys(LINK_TYPES).map((type) => (
                <option value={type}>{LINK_TYPES[type]}</option>
              ))}
            </select>

            <input
              type="text"
              name="url"
              className="flex-1 form-input border border-gray-300 rounded-lg p-2 focus:ring-blue-500 focus:border-blue-500 transition ease-in-out duration-150"
              placeholder="Enter URL"
              value={newLink.url}
              onChange={handleInputChange}
            />

            <div>
              <DefaultButton sm label="Add Link" onClick={handleSubmit} />
            </div>
          </div>

          <table className="min-w-full table-auto bg-white shadow-md rounded-lg">
            <thead className="bg-gray-100">
              <tr>
                <th className="p-4 text-left text-sm font-semibold text-gray-600 border-b">Type</th>
                <th className="p-4 text-left text-sm font-semibold text-gray-600 border-b">Link</th>
                <th className="p-4 text-left text-sm font-semibold text-gray-600 border-b">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {links.map((link) => (
                <tr key={link.id} className="hover:bg-gray-50 transition-colors">
                  <td className="p-4 text-sm text-gray-700 border-b">{LINK_TYPES[link.type]}</td>
                  <td className="p-4 text-sm text-blue-600 border-b">
                    <a
                      href={link.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:underline">
                      {link.url}
                    </a>
                  </td>
                  <td className="p-4 text-sm text-gray-700 border-b">
                    <button
                      className="text-red-500 hover:text-red-700 transition-colors"
                      onClick={() => handleDelete(link.id)}
                      disabled={loading}>
                      <FaTrashAlt />
                    </button>
                  </td>
                </tr>
              ))}
              {links.length === 0 && (
                <tr>
                  <td colSpan="3" className="p-4 text-center text-gray-500">
                    No links found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </LinksModal>
    </>
  );
};

export default ManageLinks;
